@import url(https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
  z-index: 50;
}

html {
  font-size: 62.5%;
}
.nav-a {
  margin-top: 2rem;
}

a {
  text-decoration: none;

}

li {
  list-style: none;
}

/* 
 

/* navbar style start  */

.main-nav {
  width: 100%;
  height: 10rem;
  display: grid;
  grid-template-columns: 10rem 1fr 2fr 1fr 10rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
  rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  background-color: rgb(0, 0, 0);
}
.logo-img{
  width: 4rem; 
  height: 4rem; 
  margin-bottom: 1rem ;
} 
.logo {
  display: flex;
  margin-left: 15px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
}
.menu-link {
  grid-column: 3/4;
  width: 1000px;
}
.menu-link ul {
  height: 10rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 904px;
  margin-left: 20rem;

}
.social-media {
  grid-column: 4/5;
}
.social-media ul {
  height: 10rem;
  display: grid;
  grid-template-columns: 3fr repeat(3, 1fr);
  align-items: center;
  justify-content: flex-end;
}

.social-media ul li {
  text-align: right;
}

.social-media ul li:first-child {
  grid-column: 2/3;
}

/* ----------- Grid part ends ----------------- */

.logo h2 {
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#eee, rgb(0, 0, 0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 5px;
}
.logo h2 span {
  font-size: 3.5rem;
}
.menu-link ul li {
  font-size: 1.8rem;
  padding-left: 2rem;
  margin-left: 1rem;
}
.menu-link ul li a {
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.8);
  transition: 0.5s;
  text-decoration: underline;
  font-weight: 600;
  padding-right: 3rem;
  margin-left: 1rem;
  padding-left: 1rem;
}
.menu-link ul li:hover > a {
  -webkit-transform-origin: left;
          transform-origin: left;
  color: #ffc107;
  text-decoration: none;
  transition: 0.5s;
}
.social-media ul li {
  font-size: 1.8rem;
}
.social-media .hamburger-menu {
  display: none;
}

/* hero section   */

.hero-section {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero-section p {
  font-size: 3rem;
  text-transform: capitalize;
}

.hero-section h1 {
  font-size: 5rem;
  text-transform: uppercase;
  text-align: center;
}

/* responsive css style  */
@media (max-width: 1200px) {

div.account-wrap {
  width: 70%;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 0rem;
  flex-direction: row;
}
.menu-link ul li a {
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.8);
  transition: 0.5s;
  text-decoration: underline;
  font-weight: 600;
  padding-right: 0;
  margin-left: 0;
  padding-left: 0;
}
.menu-link ul {
  margin-left: 16rem;
}
}

@media (max-width: 1088px) {
.main-nav {
height: 7rem;
grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
}
.menu-link {
  display: none;
  }
.social-media ul {
height: 7rem;
}
.mobile-menu-link {
  grid-column: 2/4;
  position: relative;
  z-index: 99;
}

.mobile-menu-link {
    border: 1px groove #ffc107;
    background-color: rgb(0, 0, 0);
    height: 20rem;
    display: grid;
    grid-column: 2/5;
    height: 250px;
    transition: all 2s linear;
    -webkit-transform-origin: top;
            transform-origin: top;
    box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px;
    justify-content: start;
    width: 97%;
    border-radius: 0px 0px 15px 15px;
}
button.myButton-dc {
width: -webkit-fit-content;
width: -moz-fit-content;
width: fit-content;
}
.mobile-menu-link ul {
    height: 25rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
    margin: 0 auto;
  }
.mobile-menu-link ul li:first-child {
    transition-delay: 0.2s;
  }
.social-media {
    display: flex;
    justify-items: end;
    align-items: center;
    transition: all 2s linear;
    z-index: 100;
  }
.social-media .social-media-desktop {
    height: 0;
    display: none;
}
.social-media {
    height: 7rem;
    display: flex;
    justify-self: end;
    align-items: center;
}
.social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
    margin-right: 5rem;
}

.svg-nav{
  width: 35px;
  height: 35px;
}
.account-row {
  display: flex;
  margin-left: 2rem;
}

}

@media (max-width: 820px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
  }
  .mobile-menu-link {
    height: 25rem;
    width: 98%;
  }
  .menu-link ul li a {
   font-size: 2rem;
}
.account-wrap {
  margin-top: 1rem;
}

.social-media ul {
    height: 6rem;
  }
  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
    margin-right: 3rem;
  }
  .social-media .hambuger-menu-active{
    margin-bottom: 3px;
    display: block;
    font-size: 2.5rem;
    margin-right: 3rem;
  }
}

@media (max-width: 780px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
  }
  .mobile-menu-link {
    height: 25rem;
    width: 97%;
  }
  .menu-link ul li a {
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.8);
    transition: 0.5s;
    text-decoration: none;
}
.social-media .hamburger-menu {
  margin-top: 1px;
  display: block;
  font-size: 2.5rem;
  margin-right: 3rem;
}
.social-media .hambuger-menu-active {
  margin-bottom: 0px;
  display: block;
  font-size: 2.5rem;
  margin-right: 3rem;
}

  .social-media ul {
    height: 6rem;
  }

.logo-img{
    width: 40px; 
    height: 40px; 
}
.nav-a {
  margin-top: 1rem;
}
 
  /* hero section  */

  .hero-section h1 {
    font-size: 3.8rem;
  }
}
@media (max-width: 680px) {
.main-nav {
  height: 6rem;
  grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
}
.mobile-menu-link {
  width: 98%;
}
.social-media .hambuger-menu-active{
  margin-bottom: 3px;
  display: block;
  font-size: 2.5rem;
  margin-right: 10px;
}
.social-media ul {
    height: 6rem;
}
.social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
}
.social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
    margin-right: 22px;
}

}
@media (max-width: 540px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
  }
  .mobile-menu-link {
    height: 26rem;
    width: 98%;
}
  .menu-link ul li a {
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.8);
    transition: 0.5s;
    text-decoration: none;
}
  .logo,
.social-media ul {
  height: 6rem;
  }
.logo h2 {
  font-size: 2rem;
  }
.social-media .hamburger-menu {
  display: block;
  font-size: 2.5rem;
  }
.logo-img{
  width: 40px; 
  height: 40px; 
  margin-bottom: 2px ;
}

.account-row {
  margin-top: 2rem;
}
  /* hero section  */

  .hero-section h1 {
    font-size: 3.8rem;
  }
}
@media(max-width: 489px){
  .mobile-menu-link {
    width: 95%;
}
.menu-link ul li a {
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.8);
  transition: 0.5s;
  text-decoration: underline;
  font-weight: 600;
  padding-right: 3rem;
  margin-left: 1rem;
  padding-left: 0rem; 
}
.nav-a {
  margin-top: 0.5rem;
}
}
.react-responsive-modal-modal {
  max-width: 800px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: rgb(0 0 3);
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
  border: 2px groove gray;
  border-radius: 15px;
}
.myButton-dc {
  box-shadow: 0px 1px 0px 0px #fff6af;
  background: linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
  background-color: #ffec64;
  border-radius: 6px;
  border: 1px solid #ffaa22;
  display: inline-block;
  cursor: pointer;
  color: #333333;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  padding: 6px 15px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffee66;
  margin-left: 28px;
}
.account-row {
  padding-left: 0px;
  display: flex;
  justify-content: space-between;
}
.myButton-dc:hover {
  background: linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
  background-color: #ffab23;
}
.myButton-dc:active {
  position: relative;
  top: 1px;
}
.connect-wallet {
  font-size: "20px";
  font-weight: "600";
  color: "white";
  margin-left: "35px";
}
.connect-wallet {
  font-size: 20px;
  font-weight: 600;
  color: white;
  margin-left: 4rem;
}
.accout-add {
  color: rgb(255, 153, 0);
  font-size: 1.7rem;
  font-weight: 500;
  margin-left: 10px;
}
div.account-wrap {
  width: 80%;
  display: flex;
  align-items: center;
  padding-left: 6rem;
  padding-right: 0rem;
}
.accout-bal {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  font-size: 1.7rem;
  font-weight: 500;
  color: rgb(255, 153, 0);
}

@media (max-width: 1200px) {
  div.account-wrap {
    width: 70%;
    display: flex;
    align-items: center;
    padding-left: 0rem;
    padding-right: 12rem;
  }
}
@media (max-width: 1088px) {
  div.account-wrap {
    width: 70%;
    display: flex;
    align-items: center;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
@media (max-width: 820px), (max-width: 959px) {
  .account-row {
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
  }

  span.accout-bal {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 18px;
    font-weight: 500;
  }

  .account-info {
    display: "flex";
    flex-direction: "row";
    justify-content: "flex-end";
    align-content: "center";
    align-items: "center";
    width: "500px";
    margin-left: "15px";
  }
}

@media (max-width: 798px) {
  .account-info {
    display: "flex";
    flex-direction: "row";
    justify-content: "flex-end";
    align-content: "center";
    align-items: "center";
    width: "500px";
    margin-left: "15px";
  }
}

@media (max-width: 680px) {
  .accout-add {
    font-size: 1.8rem;
  }
  .account-row {
    padding-left: 0px;
  }
}
@media (max-width: 540px) {
  .account-row {
    display: flex;
    margin-top: 2rem;
  }
  button.myButton-dc {
    margin-left: -20px;
  }
  span.accout-bal {
    font-size: 1.5rem;
  }
  .accout-add {
    font-size: 1.5rem;
  }
}
@media (max-width: 489px) {
  .account-wrap {
    margin-right: 25rem;
    width: 95%;
  }
  div.account-wrap {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0rem;
    padding-right: 0rem;
  }
  span.accout-bal {
    margin-left: 1rem;
    margin-right: 5px;
    font-size: 1.6rem;
    font-weight: 500;
  }
  .account-row {
    margin-top: 2rem;
    margin-left: 1rem;
  }
  .accout-add {
    font-size: 1.7rem;
  }
  .menu-link ul li {
    font-size: 1.8rem;
    padding-left: 0rem;
    margin-left: 0rem;
  }
}

.body-vision{
    display: "flex"; Width: "100%";background: "white" ; height: 100vh
}
.vision-col{
  margin-left: 50px;
  margin-top: 50px;
  
}
.vision-header{
color:"black"; 
font-size: 40px;
margin-top: 10px;
}
.vision-text{
color:"black"; 
margin-top:100px; 
font-size:19px; 
font-weight:500;
font-family: "Jost"; 
text-align:"justify";
padding-right: 12rem;
}
.loggy2{
width: 130px;
height: 5px;
margin-bottom: 10px;
margin-top: 10px;
position: absolute; 
}
.vision-col2 {
width: 50%;
 height: 50%;
display: flex;
align-items: center;
justify-content: space-around;
margin-top: 70px;
padding-right: 2rem;
}
.vision-logo {
width: 30rem;
height: 30rem;
margin-top: 2rem;
margin-right: 10rem;
}
.vision-container{
display: flex;
flex-direction: row;
}   
@media(max-width:1200px){
.vision-logo{
width:330px;height:330px;margin-top: 60px;
}
.vision-col {
margin-top: 19px;
}
.vision-col2 {
    margin-top: 19px;
    }
.vision-header{
margin-top: 30px;
}
.vision-text {
margin-top: 79px;
font-size: 19px;
padding-right: 12rem;
}
}
@media(max-width:1024px)  {
h3.vision-text.aos-init.aos-animate {
 margin-top: 5rem;
 padding-right: 8rem;
}    
}
@media(max-width:990px){
.vision-logo{
margin-top: 6rem;
margin-right: 2rem;
}
h3.vision-text.aos-init.aos-animate {
font-size: 1.6rem;
margin-top: 6rem;
padding-right: 8rem;
}
.vision-col {
margin-top: 64px;
}
}
@media (max-width: 820px){
h3.vision-text.aos-init.aos-animate {
 margin-top:4rem;
 }
 .vision-logo{
 margin-top: 90px;
}
.vision-col {
margin-top: 34px;
}
}
@media (max-width:780px){
h3.vision-text.aos-init.aos-animate {
    font-size: 1.5rem;
    padding-right: 4rem;
 }
.vision-logo {
    width: 28rem;
    height: 28rem;
    margin-top: 12rem;
    margin-right: 5rem;
}
 }
@media (max-width:680px){
    h3.vision-text.aos-init.aos-animate {
        font-size: 1.5rem;
        padding-right: 4rem;
     }
    .vision-logo {
        width: 28rem;
        height: 28rem;
        margin-top: 12rem;
        margin-right: 1rem;
    }
    .vision-col {
        margin-top: 18px;
    }
}

 @media (max-width:590px){
h3.vision-text.aos-init.aos-animate{
    font-size: 1.4rem;
    margin-top: 4rem;
    padding-right: 2rem;
 }
 .vision-logo{
    margin-top: 10rem;
    margin-right: 0rem;
    width: 25rem;
    height: 25rem;
   }
   .vision-header {
    margin-top: 30px;
    font-size: 3.5rem;
}
 } 
 @media (max-width:490px){
h3.vision-text.aos-init.aos-animate{
font-size: 1.2rem;
font-weight: 600;
padding-right: 0;
}  
.vision-logo{
 margin-top: 10rem;
margin-right: 0rem;
width: 20rem;
height: 20rem;
}
.vision-header {
margin-top: 30px;
font-size: 3rem;
}   
 }
 @media (max-width:395px){
     .vision-col {
        width: 90%;
        height: 50%;
        margin-top: 104px;
        margin-left: 18px;
    }
    .vision-header {
        margin-top: 0px;
    }
    .vision-container {
        display: flex;
        flex-direction: column;
    }
    h3.vision-text.aos-init.aos-animate {
        font-size: 1.5rem;
    }
    .vision-col2.aos-init.aos-animate {
        margin-top: -100px;
        margin-left: 94px;
        }
 }
 @media(max-width:375px){
    .vision-col {
        width: 90%;
        height: 50%;
        margin-top: 51px;
        margin-left: 18px;
    }
.vision-logo{
margin-top: 10rem;
width: 22rem;
height: 22rem;
}
h1.heavy.aos-init.aos-animate {
    margin-top: 9rem;
}
 }

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.home-body{
display: flex; max-Width: 100%;background: black ; height:80vh; margin-top: 0px; overflow:scroll
}

.sec-title {
color: white;
font-size: 42px;
font-weight: 700;
font-family: "Poppins, sans-serif";
margin-top: 50px;
margin-left: 100px
}
.sec-title-img{
margin-left: 100px; display: block ; width: 70px
}
.homePar{
color: white;
font-size: 24px;
line-height: 1.5;
margin-left: 100px ;
margin-top:20px;
font-weight:400
}
.col1{
flex:  0 0 50%; max-width: 50%; max-height:80%
}
.home-buttonWrapper{
display: "flex";
justify-content:"center";

margin-top:30px;
margin-left:85px
}    
.home-atag{
color: black; 
font-weight:800; 
font-size:15px;
padding:14px 17px;
cursor:pointer;
box-shadow: 0px 1px 0px 0px #fff6af;
background:linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
background-color:#ffec64;
border-radius:6px;
border:1px solid #ffaa22;
text-decoration:none;
font: bold;
margin-left: 10px;
}
.home-atag:hover {
    background:linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
    background-color:#ffab23;
    color: black;
  }
.home-atag:active {
    position:relative;
    top:1px;
  }
.home-logo{ 
width: 500px; height:540px; margin-top:10px ; margin-left: 75px;}
img:before {
    z-index: 0;
    -webkit-animation: rotate-right 25s linear 0s infinite;
    animation: rotate-right 25s linear 0s infinite;
    opacity: 1;
    top: 10px;
    left: 10px;
    position: absolute;
    right: 0px;
    text-align: center;
}
.img-ant{
 position: relative;  
}
@media(max-width:1200px){
.homePar {
 font-size: 22px;
}
canvas{
width: 100%;
}
.home-atag {
margin-top: 1rem;
font-weight: 800;
font-size: 12px;
padding: 1rem 1.3rem;
text-align: center;
}
.home-logo {
width: 45rem;
height: 50rem;
margin-top: 1rem;
}
.home-buttonWrapper {
display: flex;
flex-direction: row;
align-items: center;
}
}
@media (max-width:990px){
.sec-title{
margin-left: 10%;
}
.sec-title-img{
margin-left: 10%;
}    
.homePar {
font-size: 19px;
margin-left: 10%;
}
.home-buttonWrapper {
display: flex;
margin-top: 10px;
flex-direction: column;
align-items: center;
margin-left: 10%;
width: 50%;
}
.home-atag {
margin-top: 12px;
font-weight: 600;
font-size: 15px;
padding: 5px 6px;
margin-left: 0px;
text-align: center;
margin-top: 10px; 
}
.home-logo {
width: 500px;
height: 540px;
margin-top: 10px;
margin-left: -9px;
}
}
@media(max-width:820px){
.home-body{
height: 81vh;  
}  
.home-atag{
    font-size: 15px;
    padding: 2px 6px;
    margin-top: 6px;
} 
}
@media(max-width:780px){
.home-body{
  height: 81vh;  
}  
.sec-title {
font-size: 4rem;
}  
.homePar {
font-size: 18px;
}
.home-atag {
font-size: 15px;
padding: 2px 6px;
margin-top: 4px;
}   
.home-logo {
width: 50rem;
height: 55rem;
margin-top: 30px;
margin-left: -4rem;
padding-right: 2rem;
}
.home-buttonWrapper {
margin-top: 15px;
}
}
@media(max-width:680px){
.sec-title {
font-size: 3.5rem;
margin-left: 10%;
}      
.homePar {
font-size: 17px;
margin-left: 10%;
}
.sec-title-img {
margin-left: 10%;
}
.home-buttonWrapper {
    margin-top: 1rem;
    margin-left: 10%;
    display: inline-flex;
    width: 80%;
}
.home-logo {
width: 40rem;
height: 45rem;
margin-top: 60px;
margin-left: -4rem;
padding-right: 0rem;
}
}
@media(max-width:540px){
.sec-title {
font-size: 28px;
margin-top: 5rem;
}
.homePar {
font-size: 16px;
}
.home-atag{
font-size: 15px;
} 
.home-logo {
margin-left: -8rem;
}
}
@media(max-width:489px){
.sec-title{
margin-top: 4rem;
}  
.home-logo{
    margin-left: -51px;
    width: 31rem;
    height: 35rem;
}
.home-buttonWrapper{
margin-left: 20px;
} 
.home-atag{
margin-top: 1rem;
}  
.home-buttonWrapper {
margin-top: 0rem;
}
}
@media(max-width:395px){
.sec-title{
margin-top: 4rem;
}  
.homePar {
font-size: 14px;
}

.home-logo {
    margin-left: -84px;
    width: 30rem;
    height: 35rem;
}
.col2 {
 margin-left: 3rem;
width: 20rem;
}
}
@media(max-width:375px){
.homePar {
font-size: 13px;
}
.home-buttonWrapper {
margin-left: 3rem;
}  
.home-atag {
margin-top: 2rem;
font-size: 12px;
}
.home-logo {
height: 30rem;
 width: 25rem;
padding: 0px;
margin-top: 8rem;
margin-left: -73px;
}
}


.apexcharts-legend-series {
    cursor: pointer;
    line-height: normal;
    display: flex;
    align-items: center;
    color: #ffffff;
}

.supply-head{
 display: flex;flex-direction:column ; align-items:center ;margin-top: 64px;
}
.supply-cont{ 
    display: flex;flex-direction:column ; align-items:center 
 }
.supply-h1{
color:white ; padding-top:10px; font-size:45px;margin-top:20px
}
.supply-line{
    width: 240px; height:4px; margin-bottom:40px
}
.body-suppy{
    background: black;
    height: 100vh;
    background-size: contain;
    width: 100vw;
    display: "flex";
    flex-Direction:"column";
    justify-Content: "space-around" 
   
}
.cont-sp{
    display: flex;
    flex-direction: row;
    height: 80%;
    margin-bottom: 20px;
}

@media (max-width:590px){

.supply-h1 {
    color: white;
    padding-top: 10px;
    font-size: 30px;
    margin-top: 20px;
}
}
@media (max-width:395px){

    .supply-h1 {
        color: white;
        padding-top: 10px;
        font-size: 30px;
        margin-top: 20px;
        margin-right: 26px;
    }
    img.supply-line {
        margin-right: 22px;
    }
    .supply-head.aos-init.aos-animate {
        margin-bottom: 54px;
    }
    }
    
.body-tax{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
background-color: #000000;
}
.toks-head{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}
.toks-title{
    color: white;
    padding-top: 0px;
    font-size: 45px;
    margin-bottom: 9px;
    font-weight: 600;
}
.toks-line{
 width: 140px ; height:4px ; margin-bottom:40px
}
.toks-wrapper{
display: flex;
flex-direction:column ; 
align-items:center;
justify-content: center; 
}
.toks-wraphead{
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
}
.toks-logo{
width: 20rem;
height: 12rem;
margin-top:0px;
}
.wrap-sp{
display: flex;
flex-direction: row;
width: 80%;
align-items: center;
justify-content: space-evenly;
    
}
.cont-sp {
display: flex;
flex-direction: column;
align-items: center;
padding-left: 80px;
padding-right: 80px;
padding-top: 20px;
padding-bottom: 20px;
border-radius: 40px 0px 40px 0px;
width: 500px;
background-image: url(/static/media/back1.40397ddb.png);
box-sizing: border-box;
border: 3px solid rgb(246, 195, 42); 
box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
}
.toks-p{
    color:black;font-size: 17px;font-weight:500;padding-bottom: 0;
}
.toks-par{
width:1000px;
margin-top:20px;
margin-bottom: 4rem;
text-align: center;
}
.toks-h2{
color:black;font-size:30px
}
.toks-h3{
    font-weight:500;
    color:white;
}

@media(max-width:1200px){
    .wrap-sp {
        width: 90%;
    }
.cont-sp{
width: 40%;
} 
.toks-par {
width: 70rem;
margin-top: 0rem;
text-align: center;
}
}
@media(max-width:990px){
.toks-p {
    padding-bottom: 0;
 }
 .cont-sp {
    width: 358px;
    margin-right: 14px;
}
.toks-par {
    width: 654px;
    margin-top: 14px;
    text-align: center;
}
}
@media(max-width:780px){
    .toks-p {
        color: black;
        font-size: 14px;
        font-weight: 500;
    }
     .cont-sp {
        width: 358px;
        margin-right: 14px;
    }
    .toks-par {
        width: 300px;
        margin-top: 14px;
        text-align: center;
    }
    .toks-h2 {
        color: black;
        font-size: 20px;
    }
    .toks-logo {
        width: 42px;
        height: 43px;
    }
    .wrap-sp{
        width: 85%;
    }
}
@media (max-width: 680px){
    .toks-p {
        text-align: center;
        }
    .toks-h2 {
        color: black;
        font-size: 20px;
        text-align: center;
    }
    .toks-logo {
        width: 11rem;
        height: 6rem;
    }
}
@media (max-width: 540px){
    .wrap-sp {
        width: 80%;
        height: 320px;
    }
    .toks-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .toks-h2 {
        font-size: 14px;
        font-weight: 600;
        padding-top: 11px;
    }
    .toks-p {
        color: black;
        font-size: 12px;
        font-weight: 600;
        margin-top: 10px;
    }
    .toks-wraphead.aos-init.aos-animate {
        width: 77px;
        text-align: center;
    }
    .cont-sp.aos-init.aos-animate {
        width: 50%;
    }
}
@media(max-width:395px){
    .body-taxy {
        overflow: scroll;
        width: 100%;
        background: rgb(242, 242, 242);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 1115px;
        padding-bottom: 173px;
    }
    .toks-head {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 69px;
    }
    html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
        opacity: 80;
        transition-property: opacity,-webkit-transform;
        transition-property: opacity,transform;
        transition-property: opacity,transform,-webkit-transform;
    }
    .wrap-sp {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin-top: 84px;
        justify-content: center;
        align-items: flex-end;
    }
    .toks-par.aos-init.aos-animate {
        margin-top: 10rem;
        margin-right: 2rem;
    }
    .toks-p {
        color: black;
        font-size: 14px;
        font-weight: 600;
        margin-top: 26px;
        padding-left: 5px;
        padding-right: 5px;
    }
    .toks-h2 {
        font-size: 18px;
        font-weight: 600;
        padding-top: 11px;
    }
    .toks-wraphead.aos-init.aos-animate {
        display: flex;
        flex-direction: row;
    }
    .toks-wraphead.aos-init.aos-animate {
        width: 280px;
    }
    .cont-sp.aos-init.aos-animate {
        margin-left: 48px;
        width: 90%;
    }
    .toks-h3 {
        font-weight: 500;
        margin-left: 3rem;
        margin-bottom: 32px;

    }
}
.body-roadmap{
    width: 100%;
    background: rgb(242, 242, 242);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    overflow: scroll;
}
.layout-cont{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 1200px;
    justify-content: space-between;
}
.span-road{
    font-size: 14px; font-weight: 500; color: black
}
.wrapper-main{
    max-width: 380px;
    margin: 5px;
}
.wrap-span{
    display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 20px; margin-bottom: 20px;
}
.wrap-head{
    display: flex; flex-direction: column; align-items: center;
}
.road-h1{
    color: black;
    font-size: 40px;
}
.road-h2 {font-size: 25px; color: black; font-weight: 700; margin: 0px auto 10px}
.road-img{width: 100px ; height: 140px}
@media (max-width:1200px){
    .layout-cont{
        width: 90%;
    }
    .layout3 {
        justify-content: center;
        display: flex;
    }
    .wrap-span {
        text-align: center;
    }
}
@media (max-width:990px){
    .layout-cont{
        overflow: scroll;
    }
    .span-road {
        font-size: 1.3rem;
        font-weight: 600;
    }
    .layout3 {
        width: 90%;
    }
    .road-head {
        margin-top: 3rem;
    }
}
@media (max-width:780px){
    .road-head {
        margin-top: 1rem;
    }
    .road-h2 {
        text-align: center;
        font-size: 2rem;
    }
    .layout-cont {
        width: 98%;
        overflow: scroll;
    }
    .span-road {
        font-size: 1.1rem;
    }
    .road-img {
        width: 86px;
        height: 110px;
    }
}
@media (max-width:680px){
    .layout-cont {
        width: 98%;
        overflow: scroll;
        display: flex;
        flex-direction: column;
    }
    .road-head {
        margin-top: 68rem;
    }
    .span-road {
        font-size: 1.5rem;
    }
}
@media (max-width:540px){
    .layout-cont {
        display: flex;
        flex-direction: column;
    }
    .road-head {
        margin-top: -95rem;
    }
    .body-roadmap {
        overflow: scroll;
        width: 100%;
        background: rgb(242, 242, 242);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 1115px;
        padding-bottom: 173px;
    }
    .span-road {
        font-size: 1.5rem;
        font-weight: 600;
    }
}
@media(max-width:395px){
    .road-head {
        margin-top: -111rem;
    }
    .road-h1 {
        color: black;
        font-size: 29px;
        margin-top: 20px;
    }

    html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
        opacity: 0;
        transition-property: opacity,-webkit-transform;
        transition-property: opacity,transform;
        transition-property: opacity,transform,-webkit-transform;
    }

    .span-road {
        font-size: 1.2rem;
        font-weight: 600;
    }
}    
.body-feat{
background: black;
height: 100vh;
background-size: contain;
width: 100vw;
display: flex;
flex-Direction: column;
justify-Content: space-around;
}

.lay{
display: "flex";
flex-direction: "column";
width: "100vw";
background: "black";
height: "100vh";
align-items: "center"; 
}
.lay-box{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3px;
}
.bag.aos-init.aos-animate {
margin-left: 5px;
}
.heavy{
    color: aliceblue;
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.layers-our{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}
.feat-logos{
 width: 100px ; height:100px ; margin-bottom:20px; margin-left: 5px;
}
.cont-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 80%;
}
.container6{
        display:"flex";
        flex-direction:"column";
        align-items:"center"
}
.text6 {
        color: "#bbbbbb";
        font-size:"14px"; 
        font-weight:"500";
        padding-left:"10px";
}
.header6 {
        color: "#FF9900";
        font-size:"16px"; 
        font-weight:"500";
        padding:"10px"
        
}
.wrapper6{
width:"80%";
display:"flex"
}
.boxMini6{width:"500px"}
.bag {
border: 3px groove grey;
width: 600px;
border-radius: 5px;
cursor: pointer;
}
.bag:hover{
    border: 2px groove rgb(231, 171, 5);  
    cursor: "pointer";
}
.feat-header{
    color: #FF9900;
}
.zipper{
   display: flex;
   align-items: center;
    justify-content: flex-start;
}
.feat-text{
    font-size: 14px;
    font-weight: 400;
    padding-left: 10px;
    color: white;
}
@media(max-width:1200px){
.lay-box {
width: 98%;
}
.layers-our {
margin-bottom: 10rem;
}
.feat-text {
font-size: 12px;
margin-top: 10px;
}
.bag.aos-init.aos-animate {
margin-left: 5px;
}
.feat-logos{
width: 8rem;
height: 8rem;
margin-bottom: 0;
}
.cont-box {
    width: 85%;
    height: 80%;
}  
}
@media(max-width:1024px){
.lay-box {
 width: 98%;
}
.cont-box {
width: 85%;
height: 80%;
}  
.feat-logos{
width: 6rem;
height: 6rem;
margin-bottom: 0;
}
.feat-text {
font-size: 1rem;
margin-top: 5px;
font-weight: 600;
}
}
@media(max-width:990px){
.feat-text {
font-size: 1rem;
}
}
@media(max-width:780px){
.feat-text{
    font-size: 1rem;
}
.feat-logos{
    width: 7rem;
    height: 7.5rem;
    margin-top: 2px;
}
}
@media(max-width:680px){
    .feat-logos{
        width: 6rem;
        height: 6rem;
        margin-top: 2px;
    }
    .feat-header {
        font-size: 1.2rem;
    }
    .cont-box {
        width: 90%;
        height: 80%;
    }
    .feat-text{
        font-size: 0.9rem;
    }
}
@media(max-width:580px){
    .layers-our {
        margin-bottom: 0;
        margin-top: 0rem;
    }
    .lay-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 3px;
    }
    .bag.aos-init.aos-animate {
        width: 80%;
    }
    .feat-logos{
        width: 5rem;
        height: 5rem;
    }
    .feat-header {
        font-size: 1.2rem;
        padding-left: 1rem;
    }
    .cont-box {
        width: 90%;
        overflow: scroll;
    }
    .feat-text{
        font-size: 1rem;
    }
}
@media(max-width:480px){
    .layers-our {
        margin-bottom: 0;
        margin-top: 0rem;
    }
    .lay-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 3px;
    }
    .bag.aos-init.aos-animate {
        width: 80%;
    }
    .feat-logos{
        width: 5rem;
        height: 5rem;
    }
    .feat-header {
        font-size: 1.2rem;
        padding-left: 1rem;
    }
    .cont-box {
        width: 90%;
        overflow: scroll;
    }
    .feat-text{
        font-size: 1rem;
    }
}

@media(max-width:395px){
    .bag.aos-init.aos-animate {
        width: 90%;
    }
    .lay-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 98%;
    }
    .feat-logos {
        width: 55px;
        height: 57px;
        margin-bottom: 0px;
        margin-top: 2px;
    }
    .cont-box {
        overflow: scroll;
    }
    h1.heavy {
        margin-top: 0;
    }
}
/* Iphone SE */
@media(max-width:375px){
    .cont-box {
        overflow: scroll;
    }
    h1.heavy {
        margin-top: 2rem;
    }
    .layers-our {
        margin-bottom: 0;
        margin-top: 0rem;
        overflow: scroll;
    }
    h1.heavy.aos-init.aos-animate {
        margin-top: inherit;
    }
 }
.body2 {
    background: rgb(0, 0, 0);
    background-size: contain;
    width: 100vw;
    overflow: scroll;
}   
.layout-header {
    color: rgb(246, 246, 246);
    font-size: 2.8rem;
    margin: 0px 0px 30px 0px;
    text-align: center;
    padding: 20px;
    font-weight: bold;
   
}
.layout-utilities{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.utilities-li{
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    padding: 15px;
    margin-bottom: 21px;
    border-left: 5px solid #FBBD18;
    background: #F6F3E3;
    list-style: none;
}
.details{
    padding: 0px 0px 0px 15px;
    margin-bottom: 69px;
    width: 80%;
   height: 80%;
    
}
.details h6 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0.5rem;
    font-family: inherit;
}
.details p {
    margin-top: 0;
    margin-bottom: 1rem;
    box-sizing: border-box;
    color: black;
}
@media(max-width:390px){
    ul.details {
        margin-right: 48px;
    }
}
.teamContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.teamContainer h1{
  font-size: 32px;
  color: #ffc107;
  margin-top: 10px;
}
.teamBody {
    width: 100vw;
    height: 100%;
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: scroll;
}
.teamWrapper{
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
}
.devwrapper{
 padding-bottom: 20rem;
}
.devContainer{
    font-size: 32px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.devContainer h1{
    font-size: 32px;
    color: #ffc107;
    margin-top: 10px;
}
.teamRow {
    width: 95vw;
    display: flex;
    justify-content: center;
    margin-top: 22px;
    padding-bottom: 20px;
    margin-bottom: 44px;
    align-items: center;
    padding-left: 5px;
    padding-left: 5px;
}
.teamBox{
    display: flex;
    padding-left: 10px;
   padding-right: 10px;
}
.teamBox:hover{
    border: #ffc107 groove 2px;
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.teamInfo p{
font-size: 14px;
color: aliceblue;
margin-left: 1rem;
font-weight: 500;
margin-bottom: 0.5rem;
}
.teamInfo a{
    color: aliceblue;
    font-size: 14px;
    text-decoration: none;
    margin-left: 0rem;
}
.teamImg{
    width: 100px;
    height: 130px;
}
.tgStyle{
    margin-left: 15px;
    
}

@media (max-width:1024px){
   
    .teamInfo p{
        font-size: 12px;
    }
    .teamRow {
        width: 90vw;
        display: flex;
        justify-content: center;
        margin-top: 22px;
        padding-bottom: 20px;
        margin-bottom: -31px;
        
    }
}
@media (max-width: 990px){
.teamRow {
    width: 90vw;
    display: flex;
    justify-content: center;
    margin-top: 22px;
    padding-bottom: 20px;
    margin-bottom: 1px;
    flex-direction: column;
    align-items: center;
}
.teamBox {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2rem;
}
.teamInfo p{
    font-size: 16px;
}
}
@media (max-width: 480px){
.teamRow {
    width: 90vw;
    display: flex;
    justify-content: center;
    margin-top: 22px;
    padding-bottom: 20px;
    margin-bottom: 1px;
    flex-direction: column;
    align-items: flex-start;
}
}
.footer{
   background: url(/static/media/FOOTER.d2d48eda.png);
   background-size: 100% 80px;
   background-repeat: no-repeat;
   width: 100vw;
   height: 80px;
   margin-top:  auto;
   position: fixed;
   bottom: 0;
   left: 0;
   }

.cont{
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: auto;
   padding-top: 1rem;
}
.cont-wrapper {
width: 40%;text-align: center;
}
.cont-wrapper a {  
   padding-left: 1.5rem;padding-right: 1.5rem;
}
.cont h3{
   color: white;
   margin-top: 1rem;
}
.footer-logo {
   width: 3rem;
   height: 3rem;
}
@media (max-width: 1190px) {
.footer{
background-size: 100% 80px;
width: 100%;
}
.footer-logo {
   width: 3rem;
   height: 3rem;
}

}
@media (max-width: 1100px) {
.footer{
background-size: 100% 80px;
width: 100%;
}
.cont-wrapper a{
padding-left: 2rem;
padding-right: 2rem;
}
}
@media  (min-width:769px) , (max-width:800px){
.cont-wrapper {
width: 80%;
text-align: center;
}
.footer{
background-size: 100% 80px;
width: 100%;
}
}
@media (max-width: 569px) ,(max-width:768px){
   .cont-wrapper {
      width: 80%;
      text-align: center;
  }
  .footer{
   background: url(/static/media/FOOTER.d2d48eda.png);
   background-size: 100% 80px;
   width: 100%;

}
}
@media  (min-width:481px), (max-width: 568px){
.footer{
background-size: 100% 80px;
width: 100%;
}
.footer-logo{
   width: 2.4rem;
   height: 2.4rem;
}
 }
@media  (max-width: 390px) {
.footer{
background-size: 100% 80px;
position: fixed;
bottom: 0;
}  
.cont{
display: flex;
flex-direction: column;
align-items: center;
margin-top: auto;
padding-top: 1rem;
}
div.cont-wrapper {
width: 100%;
text-align: center;
}
.cont-wrapper a {
padding-left: 1rem;
padding-right: 1rem;
}
.footer-logo{
   width: 2rem;
   height: 2rem;
}
}
.apphead{
  background-image: url(/static/media/SF-background.98c32640.png);
  background-size: cover;
  background-position: center;
  background-blend-mode: color;
  height: 81vh;
}
.appBody{
  width: 100%;
  padding-top: 0;
  padding-bottom: 2rem;
  overflow: scroll;
  height: 80%;
}
.titleHeader{
  color: #fff;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
  width: 100vw;
  height: 100vh;
}
.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.1;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}
.ant-spin-text {
   color: #f1b702; 
   font-size: 25px;
   margin-top: 5rem;
   margin-left: 2rem;
}
.mark-2 {
    width: 100vw;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.stakingTerms {
  color: #f90;
  font-size: 1.5rem;
}
span.optionDays {
  font-size: 1.5rem;
}
.marketContainerApp{
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
 
}
.stake-logo{
  width: 50px;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
  background-color: #000;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgb(255 255 255 / 85%);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
  color: white;
}
.appBody h1{
color: #e0dfdc;
letter-spacing: .1em;
text-shadow: 0px 0px 5px rgba(255, 255, 255, 1), 
0px 0px 10px rgba(255, 255, 255, 1), 
0px 0px 15px rgba(255, 255, 255, 1),
0px 0px 20px#ffc107,
0px 0px 30px#ffc107,
0px 0px 40px#ffc107,
0px 0px 55px#ffc107,
0px 0px 75px#ffc107;
margin-top: 10rem;
font-size: x-large;
padding-bottom: 25rem;
}
.assets-text{
  font-size: 14px;
}
div.row {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
}
.marketContainer {
    background-color: #1b1e19;
    width: 900px;
    height: 170px;
    margin: 0 auto;
    margin-bottom: 20px ;
    border-radius: 24px;
    padding: 8px;
    border: 2px groove gray;
}

.subContainer {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
}
.marketOption {
    display: flex;
    margin-left: 22px;
}
.optionData {
    margin-left: 10px;
    color: #fff;
    font-weight: 700;
    font-size: 1.5rem;
}
.optionPercent {
    display: block;
    font-size: 24px;
    font-weight: 1000;
    color: #f1b702;
  }
.logoImg {
    background-color: #fff;
    border-radius: 100px;
    margin-bottom: 14px;
    margin-right: 14px;
    width: 28px;
    height: 28px;
}
.marketHeader {
    color: #fff;
    font-size: 32px;
    font-weight: 800;
}
.hoverButton:hover {
   opacity: 10%;
   transition: 0.3s ease-in;
    cursor: pointer;
}
.hoverButton:active {
    /* box-shadow: 0 0 0 white; */
    box-shadow: inset;
}
.assetContainer {
    background-color: #1b1e19;
    width: 900px;
    height: auto;
    margin: 0 auto;
    top: 50%;
    border-radius: 24px;
    padding: 6px;
    padding: 8px;
    margin-top: 20px; /* space between sections */
    color: #fff;
    font-weight: 500;
    overflow: scroll;
    border: 2px groove gray;
    padding-bottom: 2rem;
}
.stakedLogoImg {
    background-color: #fff;
    border-radius: 100px;
    margin-bottom: 14px;
    margin-right: 14px;
    width: 18px;
    height: 18px;
}
.glyphContainer {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 12px;
}
.glyph {
    font-size: 34px;
}
.modal-class {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    left: 0;
    top: -400px;
    right: 0px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(231, 231, 231);
}
.modal-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.modal-content {
    width: 300px !important;
    padding: 15px 15px;
    background-color: rgb(4, 5, 5) !important;
    border: 1px solid rgb(206, 208, 217) !important;
    border-radius: 12px !important;
    margin-top: 20%;

}
.fieldContainer {
    padding-left: 0px !important;
}
.inputField {
  padding-left: 10px;
  border-radius: 36px;
  border: 1px solid #ffc107;
  height: 3rem;
  margin: 10px 0;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.inputFieldUnitsContainer {
    padding-left: 0px !important;
    padding-top: 14px;
    font-size: 10px;
    font-weight: 500;
}
.stakingTerms {
    color: #f90;
  }
 .stake-div{
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  background-color: #1b1e19;
  border-radius: 15px;
  border: 2px groove gray;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 50%;
 } 
 .stake-div:hover{
  cursor: pointer;
  border: 2px groove #f1b702;
  width: 53%;
 }
 .ant-progress-line {
  position: relative;
  width: 60%;
  font-size: 14px;
}
.stakepool-h2{
  color: #f1b702;
  padding-top: 1rem;
}  
  .stake-prog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}
.myButton {
    box-shadow: 0px 1px 0px 0px #fff6af;
    background:linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
    background-color:#ffec64;
    border-radius:6px;
    border:1px solid #ffaa22;
    display:inline-block;
    cursor:pointer;
    color:#333333;
    font-family:Arial;
    font-size:13px;
    font-weight:bold;
    padding:6px 24px;
    text-decoration:none;
    text-shadow:0px 1px 0px #ffee66;
    margin-left: -9px;
  }
.myButton:hover {
    background:linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
    background-color:#ffab23;
  }
.myButton:active {
    position:relative;
    top:1px;
  }
.myButton-stake {
  box-shadow: 0px 1px 0px 0px #fff6af;
  background:linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
  background-color:#ffec64;
  border-radius:6px;
  border:1px solid #ffaa22;
  display:inline-block;
  cursor:pointer;
  color:#333333;
  font-family:Arial;
    font-size:15px;
    font-weight:bold;
    padding:6px 24px;
    text-decoration:none;
    text-shadow:0px 1px 0px #ffee66;
  }
.myButton-stake:hover {
    background:linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
    background-color:#ffab23;
  }
.myButton-stake:active {
    position:relative;
    top:1px;
  }
.columnHeaders {
    font-weight: 800;
    color: #f1b702;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 86rem;
    margin-right: 2rem;
}
.col-md-2.assets-text {
  font-size: 1.7rem;
  width: 12.5rem;
}
.myButton-X {
  box-shadow: 0px 1px 0px 0px #fff6af;
  background: linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
  background-color: #ffec64;
  border-radius: 6px;
  border: 1px solid #ffaa22;
  display: inline-block;
  cursor: pointer;
  color: #333333;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffee66;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.myButton-X:hover {
  background:linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
  background-color:#ffab23;
}
.myButton-X:active {
  position:relative;
  top:1px;
}
.assets-wrap{
  margin-left: 2rem;
}
.stake-div {
  width: 60%;
  }
  .stakepool-h2 {
    color: #f1b702;
    padding-top: 1rem;
  }
@media (max-width: 1200px) {
.appBody {
 height: 80%;
 padding-top: 0%;
}
.marketContainer{
      width: 75%;
}
.assetContainer{
      width: 75%;
      height: auto;
}
.row {
 margin-left: 1%;
}
.columnHeaders {
  font-weight: 800;
  width: 99%;
  margin-left: 2rem;
}
.assets-wrap{
  margin-left: 0;
}
.logoImg {
  margin-left: 0;
}

}
@media (max-width: 1024px) {
.col-md-2.assets-text {
      font-size: 1.7rem;
      width: 9rem;
      margin-right: auto;
  }
  .logoImg {
    margin-left: 0;
}
}
@media (max-width: 990px) {
.marketContainer {
  width: 90%;
}
.assetContainer {
    width: 90%;
    overflow: scroll;
}
.row.row-botton {
  width: 98%;
}
.optionDays{
  font-size: 1.2rem;
}
.optionPercent {
  font-size: 2.5rem;
}
.assets-wrap{
  margin-left: 0rem;
  width: 98%;
 }
.columnHeaders {
  width: 97%;
}
.logoImg {
  margin-left: -10px;
}
} 
@media (max-width: 820px) { 

.marketContainer {
  width: 90%;
  }
.assetContainer {
   width: 90%;
}
.marketOption {
  margin-left: 2%;
}
.row.row-botton {
  width: 97%;
} 
}
@media (max-width: 780px){

.col-md-3.inputFieldUnitsContainer {
  margin-left: 2rem;
}
.optionDays {
  font-size: 0.8rem;
}
div.col-md-3 {
  width: 20%;
  margin-right: 5%;
}

.inputField {
  width: 16rem;
}
}
@media (max-width: 680px){
.col-md-2.assets-text.unlock {
  width: 12%;
  font-size: 1.3rem;
  padding-right: 4rem;
}
.row.row-botton {
  width: 97%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.col-md-2.assets-text {
  font-size: 1.5rem;
  width: auto;
}
.marketContainer {
  margin-top: 0;
}
span.optionDays{
  font-size: 1.2rem;
}
.marketHeader{
  font-size: 3rem;
}
.columnHeaders {
  width: 92%;
  display: flex;
  justify-content: space-between;
  margin-left: 3rem;
}
.myButton {
  font-size: 1.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.optionPercent {
font-size: 2rem;
}
}
@media(max-width: 540px){
.myButton {
  font-size: 1.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.columnHeaders {
  width: 96%;
}
.marketHeader{
    font-size: 2rem;
  }
.col-md-3.inputFieldUnitsContainer {
  margin-left: 2rem;
}
.inputField {
  width: 16rem;
}
.appBody h1 {
  padding-top: 4rem;
  font-size: medium;
}
.auth {
  width: 134px;
  padding-left: 5px;
  padding-right: 5px;
}
div.col-md-3 {
  width: 15%;
  margin-right: 5%;
}
.col-md-2.assets-text.unlock {
  width: 16%;
  font-size: 1.2rem;
  padding-right: 0rem;
  margin-left: -2%;
}
.optionPercent {
  font-size: 1.5rem;
}
.marketContainer{
  margin-top: 1rem;
  height: -webkit-max-content;
  height: max-content;
}
.col-md-2.assets-text {
  font-size: 1.2rem;
  width: 7rem;
}
.assets-wrap {
  margin-left: -1rem;
  width: 98%;
}
div.row {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  margin-left: -2rem;
  padding-right: 2rem;
}
.row.row-botton {
  width: 99%;
  margin-left: 0rem;
  padding-left: 0rem;
  padding-right: 0rem;
}
.stake-logo {
  width: 39px;
  margin-bottom: 2rem;
}
}
@media(max-width: 489px){
  .apphead {
     height: 84vh;
}
.marketContainerApp{
  height: 92vh;
}
.appBody h1{
  font-size: initial;
}
.marketHeader {
  font-size: 1.5rem;
}
.optionData{
  margin-left: 0.5rem;
}
.stake-logo {
  width: 30px;
  margin-bottom: 2rem;
}
span.optionDays {
  font-size: 1rem;
}
.marketContainer{
  height: -webkit-max-content;
  height: max-content;
}
.optionPercent {
  font-size: 1.3rem;
}
.col-md-2.assets-text {
  font-size: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.logoImg {
  margin-bottom: 0.3rem;
  margin-right: 0rem;
  width: 16px;
  height: 16px;
}
.col-md-2.assets-text.unlock {
  width: 12%;
  font-size: 0.9rem;
  margin-left: 0;
  text-align: center;

}
.columnHeaders {
  width: 102%;
  padding-right: 0rem;
  margin-left: 0;
}

.assets-wrap {
  margin-left: 0rem;
  width: 100%;
}
/*Wallet Connect Button */
.myButton {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.8rem;
  margin-bottom: 0.4rem;
  margin-left: 1rem;
  margin-right: -1.5rem;
}
.logospam {
  padding-left: 0.5rem;
}
.stake-div {
  width: 90%;
}
.stakepool-h2 {
  color: #f1b702;
  padding-top: 1rem;
  font-size: 10px;
}
.ant-progress-line {
  position: relative;
  width: 80%;
  font-size: 14px;
}
.modal-class {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(231, 231, 231);
  height: 50%;
}

.stake-div:hover {
  cursor: pointer;
  border: 2px groove #f1b702;
  width: 90%;
}
}

@media (max-width:375px){
  .stakepool-h2 {
    font-size: 10px;
  }
  .stake-div {
    width: 90%;
  }
}
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
@media(max-width:395px){
    .app{
        width: 100%;
    }
}
@media(max-width:375px){
    .app{
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
}
@media(max-width:360px){
    .app{
        width: 100%;
    }
}
