.body-tax{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
background-color: #000000;
}
.toks-head{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}
.toks-title{
    color: white;
    padding-top: 0px;
    font-size: 45px;
    margin-bottom: 9px;
    font-weight: 600;
}
.toks-line{
 width: 140px ; height:4px ; margin-bottom:40px
}
.toks-wrapper{
display: flex;
flex-direction:column ; 
align-items:center;
justify-content: center; 
}
.toks-wraphead{
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
}
.toks-logo{
width: 20rem;
height: 12rem;
margin-top:0px;
}
.wrap-sp{
display: flex;
flex-direction: row;
width: 80%;
align-items: center;
justify-content: space-evenly;
    
}
.cont-sp {
display: flex;
flex-direction: column;
align-items: center;
padding-left: 80px;
padding-right: 80px;
padding-top: 20px;
padding-bottom: 20px;
border-radius: 40px 0px 40px 0px;
width: 500px;
background-image: url(./back1.png);
box-sizing: border-box;
border: 3px solid rgb(246, 195, 42);
-webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
}
.toks-p{
    color:black;font-size: 17px;font-weight:500;padding-bottom: 0;
}
.toks-par{
width:1000px;
margin-top:20px;
margin-bottom: 4rem;
text-align: center;
}
.toks-h2{
color:black;font-size:30px
}
.toks-h3{
    font-weight:500;
    color:white;
}

@media(max-width:1200px){
    .wrap-sp {
        width: 90%;
    }
.cont-sp{
width: 40%;
} 
.toks-par {
width: 70rem;
margin-top: 0rem;
text-align: center;
}
}
@media(max-width:990px){
.toks-p {
    padding-bottom: 0;
 }
 .cont-sp {
    width: 358px;
    margin-right: 14px;
}
.toks-par {
    width: 654px;
    margin-top: 14px;
    text-align: center;
}
}
@media(max-width:780px){
    .toks-p {
        color: black;
        font-size: 14px;
        font-weight: 500;
    }
     .cont-sp {
        width: 358px;
        margin-right: 14px;
    }
    .toks-par {
        width: 300px;
        margin-top: 14px;
        text-align: center;
    }
    .toks-h2 {
        color: black;
        font-size: 20px;
    }
    .toks-logo {
        width: 42px;
        height: 43px;
    }
    .wrap-sp{
        width: 85%;
    }
}
@media (max-width: 680px){
    .toks-p {
        text-align: center;
        }
    .toks-h2 {
        color: black;
        font-size: 20px;
        text-align: center;
    }
    .toks-logo {
        width: 11rem;
        height: 6rem;
    }
}
@media (max-width: 540px){
    .wrap-sp {
        width: 80%;
        height: 320px;
    }
    .toks-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .toks-h2 {
        font-size: 14px;
        font-weight: 600;
        padding-top: 11px;
    }
    .toks-p {
        color: black;
        font-size: 12px;
        font-weight: 600;
        margin-top: 10px;
    }
    .toks-wraphead.aos-init.aos-animate {
        width: 77px;
        text-align: center;
    }
    .cont-sp.aos-init.aos-animate {
        width: 50%;
    }
}
@media(max-width:395px){
    .body-taxy {
        overflow: scroll;
        width: 100%;
        background: rgb(242, 242, 242);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 1115px;
        padding-bottom: 173px;
    }
    .toks-head {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 69px;
    }
    html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
        opacity: 80;
        transition-property: opacity,transform;
    }
    .wrap-sp {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin-top: 84px;
        justify-content: center;
        align-items: flex-end;
    }
    .toks-par.aos-init.aos-animate {
        margin-top: 10rem;
        margin-right: 2rem;
    }
    .toks-p {
        color: black;
        font-size: 14px;
        font-weight: 600;
        margin-top: 26px;
        padding-left: 5px;
        padding-right: 5px;
    }
    .toks-h2 {
        font-size: 18px;
        font-weight: 600;
        padding-top: 11px;
    }
    .toks-wraphead.aos-init.aos-animate {
        display: flex;
        flex-direction: row;
    }
    .toks-wraphead.aos-init.aos-animate {
        width: 280px;
    }
    .cont-sp.aos-init.aos-animate {
        margin-left: 48px;
        width: 90%;
    }
    .toks-h3 {
        font-weight: 500;
        margin-left: 3rem;
        margin-bottom: 32px;

    }
}