.body2 {
    background: rgb(0, 0, 0);
    background-size: contain;
    width: 100vw;
    overflow: scroll;
}   
.layout-header {
    color: rgb(246, 246, 246);
    font-size: 2.8rem;
    margin: 0px 0px 30px 0px;
    text-align: center;
    padding: 20px;
    font-weight: bold;
   
}
.layout-utilities{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.utilities-li{
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    padding: 15px;
    margin-bottom: 21px;
    border-left: 5px solid #FBBD18;
    background: #F6F3E3;
    list-style: none;
}
.details{
    padding: 0px 0px 0px 15px;
    margin-bottom: 69px;
    width: 80%;
   height: 80%;
    
}
.details h6 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0.5rem;
    font-family: inherit;
}
.details p {
    margin-top: 0;
    margin-bottom: 1rem;
    box-sizing: border-box;
    color: black;
}
@media(max-width:390px){
    ul.details {
        margin-right: 48px;
    }
}