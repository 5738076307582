.apexcharts-legend-series {
    cursor: pointer;
    line-height: normal;
    display: flex;
    align-items: center;
    color: #ffffff;
}

.supply-head{
 display: flex;flex-direction:column ; align-items:center ;margin-top: 64px;
}
.supply-cont{ 
    display: flex;flex-direction:column ; align-items:center 
 }
.supply-h1{
color:white ; padding-top:10px; font-size:45px;margin-top:20px
}
.supply-line{
    width: 240px; height:4px; margin-bottom:40px
}
.body-suppy{
    background: black;
    height: 100vh;
    background-size: contain;
    width: 100vw;
    display: "flex";
    flex-Direction:"column";
    justify-Content: "space-around" 
   
}
.cont-sp{
    display: flex;
    flex-direction: row;
    height: 80%;
    margin-bottom: 20px;
}

@media (max-width:590px){

.supply-h1 {
    color: white;
    padding-top: 10px;
    font-size: 30px;
    margin-top: 20px;
}
}
@media (max-width:395px){

    .supply-h1 {
        color: white;
        padding-top: 10px;
        font-size: 30px;
        margin-top: 20px;
        margin-right: 26px;
    }
    img.supply-line {
        margin-right: 22px;
    }
    .supply-head.aos-init.aos-animate {
        margin-bottom: 54px;
    }
    }
    