.teamContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.teamContainer h1{
  font-size: 32px;
  color: #ffc107;
  margin-top: 10px;
}
.teamBody {
    width: 100vw;
    height: 100%;
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: scroll;
}
.teamWrapper{
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
}
.devwrapper{
 padding-bottom: 20rem;
}
.devContainer{
    font-size: 32px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.devContainer h1{
    font-size: 32px;
    color: #ffc107;
    margin-top: 10px;
}
.teamRow {
    width: 95vw;
    display: flex;
    justify-content: center;
    margin-top: 22px;
    padding-bottom: 20px;
    margin-bottom: 44px;
    align-items: center;
    padding-left: 5px;
    padding-left: 5px;
}
.teamBox{
    display: flex;
    padding-left: 10px;
   padding-right: 10px;
}
.teamBox:hover{
    border: #ffc107 groove 2px;
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.teamInfo p{
font-size: 14px;
color: aliceblue;
margin-left: 1rem;
font-weight: 500;
margin-bottom: 0.5rem;
}
.teamInfo a{
    color: aliceblue;
    font-size: 14px;
    text-decoration: none;
    margin-left: 0rem;
}
.teamImg{
    width: 100px;
    height: 130px;
}
.tgStyle{
    margin-left: 15px;
    
}

@media (max-width:1024px){
   
    .teamInfo p{
        font-size: 12px;
    }
    .teamRow {
        width: 90vw;
        display: flex;
        justify-content: center;
        margin-top: 22px;
        padding-bottom: 20px;
        margin-bottom: -31px;
        
    }
}
@media (max-width: 990px){
.teamRow {
    width: 90vw;
    display: flex;
    justify-content: center;
    margin-top: 22px;
    padding-bottom: 20px;
    margin-bottom: 1px;
    flex-direction: column;
    align-items: center;
}
.teamBox {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2rem;
}
.teamInfo p{
    font-size: 16px;
}
}
@media (max-width: 480px){
.teamRow {
    width: 90vw;
    display: flex;
    justify-content: center;
    margin-top: 22px;
    padding-bottom: 20px;
    margin-bottom: 1px;
    flex-direction: column;
    align-items: flex-start;
}
}