.footer{
   background: url(./FOOTER.png);
   background-size: 100% 80px;
   background-repeat: no-repeat;
   width: 100vw;
   height: 80px;
   margin-top:  auto;
   position: fixed;
   bottom: 0;
   left: 0;
   }

.cont{
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: auto;
   padding-top: 1rem;
}
.cont-wrapper {
width: 40%;text-align: center;
}
.cont-wrapper a {  
   padding-left: 1.5rem;padding-right: 1.5rem;
}
.cont h3{
   color: white;
   margin-top: 1rem;
}
.footer-logo {
   width: 3rem;
   height: 3rem;
}
@media (max-width: 1190px) {
.footer{
background-size: 100% 80px;
width: 100%;
}
.footer-logo {
   width: 3rem;
   height: 3rem;
}

}
@media (max-width: 1100px) {
.footer{
background-size: 100% 80px;
width: 100%;
}
.cont-wrapper a{
padding-left: 2rem;
padding-right: 2rem;
}
}
@media  (min-width:769px) , (max-width:800px){
.cont-wrapper {
width: 80%;
text-align: center;
}
.footer{
background-size: 100% 80px;
width: 100%;
}
}
@media (max-width: 569px) ,(max-width:768px){
   .cont-wrapper {
      width: 80%;
      text-align: center;
  }
  .footer{
   background: url(./FOOTER.png);
   background-size: 100% 80px;
   width: 100%;

}
}
@media  (min-width:481px), (max-width: 568px){
.footer{
background-size: 100% 80px;
width: 100%;
}
.footer-logo{
   width: 2.4rem;
   height: 2.4rem;
}
 }
@media  (max-width: 390px) {
.footer{
background-size: 100% 80px;
position: fixed;
bottom: 0;
}  
.cont{
display: flex;
flex-direction: column;
align-items: center;
margin-top: auto;
padding-top: 1rem;
}
div.cont-wrapper {
width: 100%;
text-align: center;
}
.cont-wrapper a {
padding-left: 1rem;
padding-right: 1rem;
}
.footer-logo{
   width: 2rem;
   height: 2rem;
}
}