.body-roadmap{
    width: 100%;
    background: rgb(242, 242, 242);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    overflow: scroll;
}
.layout-cont{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 1200px;
    justify-content: space-between;
}
.span-road{
    font-size: 14px; font-weight: 500; color: black
}
.wrapper-main{
    max-width: 380px;
    margin: 5px;
}
.wrap-span{
    display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 20px; margin-bottom: 20px;
}
.wrap-head{
    display: flex; flex-direction: column; align-items: center;
}
.road-h1{
    color: black;
    font-size: 40px;
}
.road-h2 {font-size: 25px; color: black; font-weight: 700; margin: 0px auto 10px}
.road-img{width: 100px ; height: 140px}
@media (max-width:1200px){
    .layout-cont{
        width: 90%;
    }
    .layout3 {
        justify-content: center;
        display: flex;
    }
    .wrap-span {
        text-align: center;
    }
}
@media (max-width:990px){
    .layout-cont{
        overflow: scroll;
    }
    .span-road {
        font-size: 1.3rem;
        font-weight: 600;
    }
    .layout3 {
        width: 90%;
    }
    .road-head {
        margin-top: 3rem;
    }
}
@media (max-width:780px){
    .road-head {
        margin-top: 1rem;
    }
    .road-h2 {
        text-align: center;
        font-size: 2rem;
    }
    .layout-cont {
        width: 98%;
        overflow: scroll;
    }
    .span-road {
        font-size: 1.1rem;
    }
    .road-img {
        width: 86px;
        height: 110px;
    }
}
@media (max-width:680px){
    .layout-cont {
        width: 98%;
        overflow: scroll;
        display: flex;
        flex-direction: column;
    }
    .road-head {
        margin-top: 68rem;
    }
    .span-road {
        font-size: 1.5rem;
    }
}
@media (max-width:540px){
    .layout-cont {
        display: flex;
        flex-direction: column;
    }
    .road-head {
        margin-top: -95rem;
    }
    .body-roadmap {
        overflow: scroll;
        width: 100%;
        background: rgb(242, 242, 242);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 1115px;
        padding-bottom: 173px;
    }
    .span-road {
        font-size: 1.5rem;
        font-weight: 600;
    }
}
@media(max-width:395px){
    .road-head {
        margin-top: -111rem;
    }
    .road-h1 {
        color: black;
        font-size: 29px;
        margin-top: 20px;
    }

    html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
        opacity: 0;
        transition-property: opacity,transform;
    }

    .span-road {
        font-size: 1.2rem;
        font-weight: 600;
    }
}    