.body-vision{
    display: "flex"; Width: "100%";background: "white" ; height: 100vh
}
.vision-col{
  margin-left: 50px;
  margin-top: 50px;
  
}
.vision-header{
color:"black"; 
font-size: 40px;
margin-top: 10px;
}
.vision-text{
color:"black"; 
margin-top:100px; 
font-size:19px; 
font-weight:500;
font-family: "Jost"; 
text-align:"justify";
padding-right: 12rem;
}
.loggy2{
width: 130px;
height: 5px;
margin-bottom: 10px;
margin-top: 10px;
position: absolute; 
}
.vision-col2 {
width: 50%;
 height: 50%;
display: flex;
align-items: center;
justify-content: space-around;
margin-top: 70px;
padding-right: 2rem;
}
.vision-logo {
width: 30rem;
height: 30rem;
margin-top: 2rem;
margin-right: 10rem;
}
.vision-container{
display: flex;
flex-direction: row;
}   
@media(max-width:1200px){
.vision-logo{
width:330px;height:330px;margin-top: 60px;
}
.vision-col {
margin-top: 19px;
}
.vision-col2 {
    margin-top: 19px;
    }
.vision-header{
margin-top: 30px;
}
.vision-text {
margin-top: 79px;
font-size: 19px;
padding-right: 12rem;
}
}
@media(max-width:1024px)  {
h3.vision-text.aos-init.aos-animate {
 margin-top: 5rem;
 padding-right: 8rem;
}    
}
@media(max-width:990px){
.vision-logo{
margin-top: 6rem;
margin-right: 2rem;
}
h3.vision-text.aos-init.aos-animate {
font-size: 1.6rem;
margin-top: 6rem;
padding-right: 8rem;
}
.vision-col {
margin-top: 64px;
}
}
@media (max-width: 820px){
h3.vision-text.aos-init.aos-animate {
 margin-top:4rem;
 }
 .vision-logo{
 margin-top: 90px;
}
.vision-col {
margin-top: 34px;
}
}
@media (max-width:780px){
h3.vision-text.aos-init.aos-animate {
    font-size: 1.5rem;
    padding-right: 4rem;
 }
.vision-logo {
    width: 28rem;
    height: 28rem;
    margin-top: 12rem;
    margin-right: 5rem;
}
 }
@media (max-width:680px){
    h3.vision-text.aos-init.aos-animate {
        font-size: 1.5rem;
        padding-right: 4rem;
     }
    .vision-logo {
        width: 28rem;
        height: 28rem;
        margin-top: 12rem;
        margin-right: 1rem;
    }
    .vision-col {
        margin-top: 18px;
    }
}

 @media (max-width:590px){
h3.vision-text.aos-init.aos-animate{
    font-size: 1.4rem;
    margin-top: 4rem;
    padding-right: 2rem;
 }
 .vision-logo{
    margin-top: 10rem;
    margin-right: 0rem;
    width: 25rem;
    height: 25rem;
   }
   .vision-header {
    margin-top: 30px;
    font-size: 3.5rem;
}
 } 
 @media (max-width:490px){
h3.vision-text.aos-init.aos-animate{
font-size: 1.2rem;
font-weight: 600;
padding-right: 0;
}  
.vision-logo{
 margin-top: 10rem;
margin-right: 0rem;
width: 20rem;
height: 20rem;
}
.vision-header {
margin-top: 30px;
font-size: 3rem;
}   
 }
 @media (max-width:395px){
     .vision-col {
        width: 90%;
        height: 50%;
        margin-top: 104px;
        margin-left: 18px;
    }
    .vision-header {
        margin-top: 0px;
    }
    .vision-container {
        display: flex;
        flex-direction: column;
    }
    h3.vision-text.aos-init.aos-animate {
        font-size: 1.5rem;
    }
    .vision-col2.aos-init.aos-animate {
        margin-top: -100px;
        margin-left: 94px;
        }
 }
 @media(max-width:375px){
    .vision-col {
        width: 90%;
        height: 50%;
        margin-top: 51px;
        margin-left: 18px;
    }
.vision-logo{
margin-top: 10rem;
width: 22rem;
height: 22rem;
}
h1.heavy.aos-init.aos-animate {
    margin-top: 9rem;
}
 }
