.react-responsive-modal-modal {
  max-width: 800px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: rgb(0 0 3);
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
  border: 2px groove gray;
  border-radius: 15px;
}
.myButton-dc {
  box-shadow: 0px 1px 0px 0px #fff6af;
  background: linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
  background-color: #ffec64;
  border-radius: 6px;
  border: 1px solid #ffaa22;
  display: inline-block;
  cursor: pointer;
  color: #333333;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  padding: 6px 15px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffee66;
  margin-left: 28px;
}
.account-row {
  padding-left: 0px;
  display: flex;
  justify-content: space-between;
}
.myButton-dc:hover {
  background: linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
  background-color: #ffab23;
}
.myButton-dc:active {
  position: relative;
  top: 1px;
}
.connect-wallet {
  font-size: "20px";
  font-weight: "600";
  color: "white";
  margin-left: "35px";
}
.connect-wallet {
  font-size: 20px;
  font-weight: 600;
  color: white;
  margin-left: 4rem;
}
.accout-add {
  color: rgb(255, 153, 0);
  font-size: 1.7rem;
  font-weight: 500;
  margin-left: 10px;
}
div.account-wrap {
  width: 80%;
  display: flex;
  align-items: center;
  padding-left: 6rem;
  padding-right: 0rem;
}
.accout-bal {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  font-size: 1.7rem;
  font-weight: 500;
  color: rgb(255, 153, 0);
}

@media (max-width: 1200px) {
  div.account-wrap {
    width: 70%;
    display: flex;
    align-items: center;
    padding-left: 0rem;
    padding-right: 12rem;
  }
}
@media (max-width: 1088px) {
  div.account-wrap {
    width: 70%;
    display: flex;
    align-items: center;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
@media (max-width: 820px), (max-width: 959px) {
  .account-row {
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
  }

  span.accout-bal {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 18px;
    font-weight: 500;
  }

  .account-info {
    display: "flex";
    flex-direction: "row";
    justify-content: "flex-end";
    align-content: "center";
    align-items: "center";
    width: "500px";
    margin-left: "15px";
  }
}

@media (max-width: 798px) {
  .account-info {
    display: "flex";
    flex-direction: "row";
    justify-content: "flex-end";
    align-content: "center";
    align-items: "center";
    width: "500px";
    margin-left: "15px";
  }
}

@media (max-width: 680px) {
  .accout-add {
    font-size: 1.8rem;
  }
  .account-row {
    padding-left: 0px;
  }
}
@media (max-width: 540px) {
  .account-row {
    display: flex;
    margin-top: 2rem;
  }
  button.myButton-dc {
    margin-left: -20px;
  }
  span.accout-bal {
    font-size: 1.5rem;
  }
  .accout-add {
    font-size: 1.5rem;
  }
}
@media (max-width: 489px) {
  .account-wrap {
    margin-right: 25rem;
    width: 95%;
  }
  div.account-wrap {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0rem;
    padding-right: 0rem;
  }
  span.accout-bal {
    margin-left: 1rem;
    margin-right: 5px;
    font-size: 1.6rem;
    font-weight: 500;
  }
  .account-row {
    margin-top: 2rem;
    margin-left: 1rem;
  }
  .accout-add {
    font-size: 1.7rem;
  }
  .menu-link ul li {
    font-size: 1.8rem;
    padding-left: 0rem;
    margin-left: 0rem;
  }
}
