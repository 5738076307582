*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
@media(max-width:395px){
    .app{
        width: 100%;
    }
}
@media(max-width:375px){
    .app{
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
}
@media(max-width:360px){
    .app{
        width: 100%;
    }
}