* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.home-body{
display: flex; max-Width: 100%;background: black ; height:80vh; margin-top: 0px; overflow:scroll
}

.sec-title {
color: white;
font-size: 42px;
font-weight: 700;
font-family: "Poppins, sans-serif";
margin-top: 50px;
margin-left: 100px
}
.sec-title-img{
margin-left: 100px; display: block ; width: 70px
}
.homePar{
color: white;
font-size: 24px;
line-height: 1.5;
margin-left: 100px ;
margin-top:20px;
font-weight:400
}
.col1{
flex:  0 0 50%; max-width: 50%; max-height:80%
}
.home-buttonWrapper{
display: "flex";
justify-content:"center";

margin-top:30px;
margin-left:85px
}    
.home-atag{
color: black; 
font-weight:800; 
font-size:15px;
padding:14px 17px;
cursor:pointer;
box-shadow: 0px 1px 0px 0px #fff6af;
background:linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
background-color:#ffec64;
border-radius:6px;
border:1px solid #ffaa22;
text-decoration:none;
font: bold;
margin-left: 10px;
}
.home-atag:hover {
    background:linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
    background-color:#ffab23;
    color: black;
  }
.home-atag:active {
    position:relative;
    top:1px;
  }
.home-logo{ 
width: 500px; height:540px; margin-top:10px ; margin-left: 75px;}
img:before {
    z-index: 0;
    -webkit-animation: rotate-right 25s linear 0s infinite;
    animation: rotate-right 25s linear 0s infinite;
    opacity: 1;
    top: 10px;
    left: 10px;
    position: absolute;
    right: 0px;
    text-align: center;
}
.img-ant{
 position: relative;  
}
@media(max-width:1200px){
.homePar {
 font-size: 22px;
}
canvas{
width: 100%;
}
.home-atag {
margin-top: 1rem;
font-weight: 800;
font-size: 12px;
padding: 1rem 1.3rem;
text-align: center;
}
.home-logo {
width: 45rem;
height: 50rem;
margin-top: 1rem;
}
.home-buttonWrapper {
display: flex;
flex-direction: row;
align-items: center;
}
}
@media (max-width:990px){
.sec-title{
margin-left: 10%;
}
.sec-title-img{
margin-left: 10%;
}    
.homePar {
font-size: 19px;
margin-left: 10%;
}
.home-buttonWrapper {
display: flex;
margin-top: 10px;
flex-direction: column;
align-items: center;
margin-left: 10%;
width: 50%;
}
.home-atag {
margin-top: 12px;
font-weight: 600;
font-size: 15px;
padding: 5px 6px;
margin-left: 0px;
text-align: center;
margin-top: 10px; 
}
.home-logo {
width: 500px;
height: 540px;
margin-top: 10px;
margin-left: -9px;
}
}
@media(max-width:820px){
.home-body{
height: 81vh;  
}  
.home-atag{
    font-size: 15px;
    padding: 2px 6px;
    margin-top: 6px;
} 
}
@media(max-width:780px){
.home-body{
  height: 81vh;  
}  
.sec-title {
font-size: 4rem;
}  
.homePar {
font-size: 18px;
}
.home-atag {
font-size: 15px;
padding: 2px 6px;
margin-top: 4px;
}   
.home-logo {
width: 50rem;
height: 55rem;
margin-top: 30px;
margin-left: -4rem;
padding-right: 2rem;
}
.home-buttonWrapper {
margin-top: 15px;
}
}
@media(max-width:680px){
.sec-title {
font-size: 3.5rem;
margin-left: 10%;
}      
.homePar {
font-size: 17px;
margin-left: 10%;
}
.sec-title-img {
margin-left: 10%;
}
.home-buttonWrapper {
    margin-top: 1rem;
    margin-left: 10%;
    display: inline-flex;
    width: 80%;
}
.home-logo {
width: 40rem;
height: 45rem;
margin-top: 60px;
margin-left: -4rem;
padding-right: 0rem;
}
}
@media(max-width:540px){
.sec-title {
font-size: 28px;
margin-top: 5rem;
}
.homePar {
font-size: 16px;
}
.home-atag{
font-size: 15px;
} 
.home-logo {
margin-left: -8rem;
}
}
@media(max-width:489px){
.sec-title{
margin-top: 4rem;
}  
.home-logo{
    margin-left: -51px;
    width: 31rem;
    height: 35rem;
}
.home-buttonWrapper{
margin-left: 20px;
} 
.home-atag{
margin-top: 1rem;
}  
.home-buttonWrapper {
margin-top: 0rem;
}
}
@media(max-width:395px){
.sec-title{
margin-top: 4rem;
}  
.homePar {
font-size: 14px;
}

.home-logo {
    margin-left: -84px;
    width: 30rem;
    height: 35rem;
}
.col2 {
 margin-left: 3rem;
width: 20rem;
}
}
@media(max-width:375px){
.homePar {
font-size: 13px;
}
.home-buttonWrapper {
margin-left: 3rem;
}  
.home-atag {
margin-top: 2rem;
font-size: 12px;
}
.home-logo {
height: 30rem;
 width: 25rem;
padding: 0px;
margin-top: 8rem;
margin-left: -73px;
}
}

