.body-feat{
background: black;
height: 100vh;
background-size: contain;
width: 100vw;
display: flex;
flex-Direction: column;
justify-Content: space-around;
}

.lay{
display: "flex";
flex-direction: "column";
width: "100vw";
background: "black";
height: "100vh";
align-items: "center"; 
}
.lay-box{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3px;
}
.bag.aos-init.aos-animate {
margin-left: 5px;
}
.heavy{
    color: aliceblue;
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.layers-our{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}
.feat-logos{
 width: 100px ; height:100px ; margin-bottom:20px; margin-left: 5px;
}
.cont-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 80%;
}
.container6{
        display:"flex";
        flex-direction:"column";
        align-items:"center"
}
.text6 {
        color: "#bbbbbb";
        font-size:"14px"; 
        font-weight:"500";
        padding-left:"10px";
}
.header6 {
        color: "#FF9900";
        font-size:"16px"; 
        font-weight:"500";
        padding:"10px"
        
}
.wrapper6{
width:"80%";
display:"flex"
}
.boxMini6{width:"500px"}
.bag {
border: 3px groove grey;
width: 600px;
border-radius: 5px;
cursor: pointer;
}
.bag:hover{
    border: 2px groove rgb(231, 171, 5);  
    cursor: "pointer";
}
.feat-header{
    color: #FF9900;
}
.zipper{
   display: flex;
   align-items: center;
    justify-content: flex-start;
}
.feat-text{
    font-size: 14px;
    font-weight: 400;
    padding-left: 10px;
    color: white;
}
@media(max-width:1200px){
.lay-box {
width: 98%;
}
.layers-our {
margin-bottom: 10rem;
}
.feat-text {
font-size: 12px;
margin-top: 10px;
}
.bag.aos-init.aos-animate {
margin-left: 5px;
}
.feat-logos{
width: 8rem;
height: 8rem;
margin-bottom: 0;
}
.cont-box {
    width: 85%;
    height: 80%;
}  
}
@media(max-width:1024px){
.lay-box {
 width: 98%;
}
.cont-box {
width: 85%;
height: 80%;
}  
.feat-logos{
width: 6rem;
height: 6rem;
margin-bottom: 0;
}
.feat-text {
font-size: 1rem;
margin-top: 5px;
font-weight: 600;
}
}
@media(max-width:990px){
.feat-text {
font-size: 1rem;
}
}
@media(max-width:780px){
.feat-text{
    font-size: 1rem;
}
.feat-logos{
    width: 7rem;
    height: 7.5rem;
    margin-top: 2px;
}
}
@media(max-width:680px){
    .feat-logos{
        width: 6rem;
        height: 6rem;
        margin-top: 2px;
    }
    .feat-header {
        font-size: 1.2rem;
    }
    .cont-box {
        width: 90%;
        height: 80%;
    }
    .feat-text{
        font-size: 0.9rem;
    }
}
@media(max-width:580px){
    .layers-our {
        margin-bottom: 0;
        margin-top: 0rem;
    }
    .lay-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 3px;
    }
    .bag.aos-init.aos-animate {
        width: 80%;
    }
    .feat-logos{
        width: 5rem;
        height: 5rem;
    }
    .feat-header {
        font-size: 1.2rem;
        padding-left: 1rem;
    }
    .cont-box {
        width: 90%;
        overflow: scroll;
    }
    .feat-text{
        font-size: 1rem;
    }
}
@media(max-width:480px){
    .layers-our {
        margin-bottom: 0;
        margin-top: 0rem;
    }
    .lay-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 3px;
    }
    .bag.aos-init.aos-animate {
        width: 80%;
    }
    .feat-logos{
        width: 5rem;
        height: 5rem;
    }
    .feat-header {
        font-size: 1.2rem;
        padding-left: 1rem;
    }
    .cont-box {
        width: 90%;
        overflow: scroll;
    }
    .feat-text{
        font-size: 1rem;
    }
}

@media(max-width:395px){
    .bag.aos-init.aos-animate {
        width: 90%;
    }
    .lay-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 98%;
    }
    .feat-logos {
        width: 55px;
        height: 57px;
        margin-bottom: 0px;
        margin-top: 2px;
    }
    .cont-box {
        overflow: scroll;
    }
    h1.heavy {
        margin-top: 0;
    }
}
/* Iphone SE */
@media(max-width:375px){
    .cont-box {
        overflow: scroll;
    }
    h1.heavy {
        margin-top: 2rem;
    }
    .layers-our {
        margin-bottom: 0;
        margin-top: 0rem;
        overflow: scroll;
    }
    h1.heavy.aos-init.aos-animate {
        margin-top: inherit;
    }
 }