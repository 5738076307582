.apphead{
  background-image: url(./SF-background.png);
  background-size: cover;
  background-position: center;
  background-blend-mode: color;
  height: 81vh;
}
.appBody{
  width: 100%;
  padding-top: 0;
  padding-bottom: 2rem;
  overflow: scroll;
  height: 80%;
}
.titleHeader{
  color: #fff;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
  width: 100vw;
  height: 100vh;
}
.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}
.ant-spin-text {
   color: #f1b702; 
   font-size: 25px;
   margin-top: 5rem;
   margin-left: 2rem;
}
.mark-2 {
    width: 100vw;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.stakingTerms {
  color: #f90;
  font-size: 1.5rem;
}
span.optionDays {
  font-size: 1.5rem;
}
.marketContainerApp{
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
 
}
.stake-logo{
  width: 50px;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
  background-color: #000;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgb(255 255 255 / 85%);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
  color: white;
}
.appBody h1{
color: #e0dfdc;
letter-spacing: .1em;
text-shadow: 0px 0px 5px rgba(255, 255, 255, 1), 
0px 0px 10px rgba(255, 255, 255, 1), 
0px 0px 15px rgba(255, 255, 255, 1),
0px 0px 20px#ffc107,
0px 0px 30px#ffc107,
0px 0px 40px#ffc107,
0px 0px 55px#ffc107,
0px 0px 75px#ffc107;
margin-top: 10rem;
font-size: x-large;
padding-bottom: 25rem;
}
.assets-text{
  font-size: 14px;
}
div.row {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
}
.marketContainer {
    background-color: #1b1e19;
    width: 900px;
    height: 170px;
    margin: 0 auto;
    margin-bottom: 20px ;
    border-radius: 24px;
    padding: 8px;
    border: 2px groove gray;
}

.subContainer {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
}
.marketOption {
    display: flex;
    margin-left: 22px;
}
.optionData {
    margin-left: 10px;
    color: #fff;
    font-weight: 700;
    font-size: 1.5rem;
}
.optionPercent {
    display: block;
    font-size: 24px;
    font-weight: 1000;
    color: #f1b702;
  }
.logoImg {
    background-color: #fff;
    border-radius: 100px;
    margin-bottom: 14px;
    margin-right: 14px;
    width: 28px;
    height: 28px;
}
.marketHeader {
    color: #fff;
    font-size: 32px;
    font-weight: 800;
}
.hoverButton:hover {
   opacity: 10%;
   transition: 0.3s ease-in;
    cursor: pointer;
}
.hoverButton:active {
    /* box-shadow: 0 0 0 white; */
    box-shadow: inset;
}
.assetContainer {
    background-color: #1b1e19;
    width: 900px;
    height: auto;
    margin: 0 auto;
    top: 50%;
    border-radius: 24px;
    padding: 6px;
    padding: 8px;
    margin-top: 20px; /* space between sections */
    color: #fff;
    font-weight: 500;
    overflow: scroll;
    border: 2px groove gray;
    padding-bottom: 2rem;
}
.stakedLogoImg {
    background-color: #fff;
    border-radius: 100px;
    margin-bottom: 14px;
    margin-right: 14px;
    width: 18px;
    height: 18px;
}
.glyphContainer {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 12px;
}
.glyph {
    font-size: 34px;
}
.modal-class {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    left: 0;
    top: -400px;
    right: 0px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(231, 231, 231);
}
.modal-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.modal-content {
    width: 300px !important;
    padding: 15px 15px;
    background-color: rgb(4, 5, 5) !important;
    border: 1px solid rgb(206, 208, 217) !important;
    border-radius: 12px !important;
    margin-top: 20%;

}
.fieldContainer {
    padding-left: 0px !important;
}
.inputField {
  padding-left: 10px;
  border-radius: 36px;
  border: 1px solid #ffc107;
  height: 3rem;
  margin: 10px 0;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.inputFieldUnitsContainer {
    padding-left: 0px !important;
    padding-top: 14px;
    font-size: 10px;
    font-weight: 500;
}
.stakingTerms {
    color: #f90;
  }
 .stake-div{
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  background-color: #1b1e19;
  border-radius: 15px;
  border: 2px groove gray;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 50%;
 } 
 .stake-div:hover{
  cursor: pointer;
  border: 2px groove #f1b702;
  width: 53%;
 }
 .ant-progress-line {
  position: relative;
  width: 60%;
  font-size: 14px;
}
.stakepool-h2{
  color: #f1b702;
  padding-top: 1rem;
}  
  .stake-prog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}
.myButton {
    box-shadow: 0px 1px 0px 0px #fff6af;
    background:linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
    background-color:#ffec64;
    border-radius:6px;
    border:1px solid #ffaa22;
    display:inline-block;
    cursor:pointer;
    color:#333333;
    font-family:Arial;
    font-size:13px;
    font-weight:bold;
    padding:6px 24px;
    text-decoration:none;
    text-shadow:0px 1px 0px #ffee66;
    margin-left: -9px;
  }
.myButton:hover {
    background:linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
    background-color:#ffab23;
  }
.myButton:active {
    position:relative;
    top:1px;
  }
.myButton-stake {
  box-shadow: 0px 1px 0px 0px #fff6af;
  background:linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
  background-color:#ffec64;
  border-radius:6px;
  border:1px solid #ffaa22;
  display:inline-block;
  cursor:pointer;
  color:#333333;
  font-family:Arial;
    font-size:15px;
    font-weight:bold;
    padding:6px 24px;
    text-decoration:none;
    text-shadow:0px 1px 0px #ffee66;
  }
.myButton-stake:hover {
    background:linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
    background-color:#ffab23;
  }
.myButton-stake:active {
    position:relative;
    top:1px;
  }
.columnHeaders {
    font-weight: 800;
    color: #f1b702;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 86rem;
    margin-right: 2rem;
}
.col-md-2.assets-text {
  font-size: 1.7rem;
  width: 12.5rem;
}
.myButton-X {
  box-shadow: 0px 1px 0px 0px #fff6af;
  background: linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
  background-color: #ffec64;
  border-radius: 6px;
  border: 1px solid #ffaa22;
  display: inline-block;
  cursor: pointer;
  color: #333333;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffee66;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.myButton-X:hover {
  background:linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
  background-color:#ffab23;
}
.myButton-X:active {
  position:relative;
  top:1px;
}
.assets-wrap{
  margin-left: 2rem;
}
.stake-div {
  width: 60%;
  }
  .stakepool-h2 {
    color: #f1b702;
    padding-top: 1rem;
  }
@media (max-width: 1200px) {
.appBody {
 height: 80%;
 padding-top: 0%;
}
.marketContainer{
      width: 75%;
}
.assetContainer{
      width: 75%;
      height: auto;
}
.row {
 margin-left: 1%;
}
.columnHeaders {
  font-weight: 800;
  width: 99%;
  margin-left: 2rem;
}
.assets-wrap{
  margin-left: 0;
}
.logoImg {
  margin-left: 0;
}

}
@media (max-width: 1024px) {
.col-md-2.assets-text {
      font-size: 1.7rem;
      width: 9rem;
      margin-right: auto;
  }
  .logoImg {
    margin-left: 0;
}
}
@media (max-width: 990px) {
.marketContainer {
  width: 90%;
}
.assetContainer {
    width: 90%;
    overflow: scroll;
}
.row.row-botton {
  width: 98%;
}
.optionDays{
  font-size: 1.2rem;
}
.optionPercent {
  font-size: 2.5rem;
}
.assets-wrap{
  margin-left: 0rem;
  width: 98%;
 }
.columnHeaders {
  width: 97%;
}
.logoImg {
  margin-left: -10px;
}
} 
@media (max-width: 820px) { 

.marketContainer {
  width: 90%;
  }
.assetContainer {
   width: 90%;
}
.marketOption {
  margin-left: 2%;
}
.row.row-botton {
  width: 97%;
} 
}
@media (max-width: 780px){

.col-md-3.inputFieldUnitsContainer {
  margin-left: 2rem;
}
.optionDays {
  font-size: 0.8rem;
}
div.col-md-3 {
  width: 20%;
  margin-right: 5%;
}

.inputField {
  width: 16rem;
}
}
@media (max-width: 680px){
.col-md-2.assets-text.unlock {
  width: 12%;
  font-size: 1.3rem;
  padding-right: 4rem;
}
.row.row-botton {
  width: 97%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.col-md-2.assets-text {
  font-size: 1.5rem;
  width: auto;
}
.marketContainer {
  margin-top: 0;
}
span.optionDays{
  font-size: 1.2rem;
}
.marketHeader{
  font-size: 3rem;
}
.columnHeaders {
  width: 92%;
  display: flex;
  justify-content: space-between;
  margin-left: 3rem;
}
.myButton {
  font-size: 1.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.optionPercent {
font-size: 2rem;
}
}
@media(max-width: 540px){
.myButton {
  font-size: 1.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.columnHeaders {
  width: 96%;
}
.marketHeader{
    font-size: 2rem;
  }
.col-md-3.inputFieldUnitsContainer {
  margin-left: 2rem;
}
.inputField {
  width: 16rem;
}
.appBody h1 {
  padding-top: 4rem;
  font-size: medium;
}
.auth {
  width: 134px;
  padding-left: 5px;
  padding-right: 5px;
}
div.col-md-3 {
  width: 15%;
  margin-right: 5%;
}
.col-md-2.assets-text.unlock {
  width: 16%;
  font-size: 1.2rem;
  padding-right: 0rem;
  margin-left: -2%;
}
.optionPercent {
  font-size: 1.5rem;
}
.marketContainer{
  margin-top: 1rem;
  height: max-content;
}
.col-md-2.assets-text {
  font-size: 1.2rem;
  width: 7rem;
}
.assets-wrap {
  margin-left: -1rem;
  width: 98%;
}
div.row {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  margin-left: -2rem;
  padding-right: 2rem;
}
.row.row-botton {
  width: 99%;
  margin-left: 0rem;
  padding-left: 0rem;
  padding-right: 0rem;
}
.stake-logo {
  width: 39px;
  margin-bottom: 2rem;
}
}
@media(max-width: 489px){
  .apphead {
     height: 84vh;
}
.marketContainerApp{
  height: 92vh;
}
.appBody h1{
  font-size: initial;
}
.marketHeader {
  font-size: 1.5rem;
}
.optionData{
  margin-left: 0.5rem;
}
.stake-logo {
  width: 30px;
  margin-bottom: 2rem;
}
span.optionDays {
  font-size: 1rem;
}
.marketContainer{
  height: max-content;
}
.optionPercent {
  font-size: 1.3rem;
}
.col-md-2.assets-text {
  font-size: 1rem;
  width: fit-content;
}
.logoImg {
  margin-bottom: 0.3rem;
  margin-right: 0rem;
  width: 16px;
  height: 16px;
}
.col-md-2.assets-text.unlock {
  width: 12%;
  font-size: 0.9rem;
  margin-left: 0;
  text-align: center;

}
.columnHeaders {
  width: 102%;
  padding-right: 0rem;
  margin-left: 0;
}

.assets-wrap {
  margin-left: 0rem;
  width: 100%;
}
/*Wallet Connect Button */
.myButton {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.8rem;
  margin-bottom: 0.4rem;
  margin-left: 1rem;
  margin-right: -1.5rem;
}
.logospam {
  padding-left: 0.5rem;
}
.stake-div {
  width: 90%;
}
.stakepool-h2 {
  color: #f1b702;
  padding-top: 1rem;
  font-size: 10px;
}
.ant-progress-line {
  position: relative;
  width: 80%;
  font-size: 14px;
}
.modal-class {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(231, 231, 231);
  height: 50%;
}

.stake-div:hover {
  cursor: pointer;
  border: 2px groove #f1b702;
  width: 90%;
}
}

@media (max-width:375px){
  .stakepool-h2 {
    font-size: 10px;
  }
  .stake-div {
    width: 90%;
  }
}