@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
  z-index: 50;
}

html {
  font-size: 62.5%;
}
.nav-a {
  margin-top: 2rem;
}

a {
  text-decoration: none;

}

li {
  list-style: none;
}

/* 
 

/* navbar style start  */

.main-nav {
  width: 100%;
  height: 10rem;
  display: grid;
  grid-template-columns: 10rem 1fr 2fr 1fr 10rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
  rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  background-color: rgb(0, 0, 0);
}
.logo-img{
  width: 4rem; 
  height: 4rem; 
  margin-bottom: 1rem ;
} 
.logo {
  display: flex;
  margin-left: 15px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
}
.menu-link {
  grid-column: 3/4;
  width: 1000px;
}
.menu-link ul {
  height: 10rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 904px;
  margin-left: 20rem;

}
.social-media {
  grid-column: 4/5;
}
.social-media ul {
  height: 10rem;
  display: grid;
  grid-template-columns: 3fr repeat(3, 1fr);
  align-items: center;
  justify-content: flex-end;
}

.social-media ul li {
  text-align: right;
}

.social-media ul li:first-child {
  grid-column: 2/3;
}

/* ----------- Grid part ends ----------------- */

.logo h2 {
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#eee, rgb(0, 0, 0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 5px;
}
.logo h2 span {
  font-size: 3.5rem;
}
.menu-link ul li {
  font-size: 1.8rem;
  padding-left: 2rem;
  margin-left: 1rem;
}
.menu-link ul li a {
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.8);
  transition: 0.5s;
  text-decoration: underline;
  font-weight: 600;
  padding-right: 3rem;
  margin-left: 1rem;
  padding-left: 1rem;
}
.menu-link ul li:hover > a {
  transform-origin: left;
  color: #ffc107;
  text-decoration: none;
  transition: 0.5s;
}
.social-media ul li {
  font-size: 1.8rem;
}
.social-media .hamburger-menu {
  display: none;
}

/* hero section   */

.hero-section {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero-section p {
  font-size: 3rem;
  text-transform: capitalize;
}

.hero-section h1 {
  font-size: 5rem;
  text-transform: uppercase;
  text-align: center;
}

/* responsive css style  */
@media (max-width: 1200px) {

div.account-wrap {
  width: 70%;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 0rem;
  flex-direction: row;
}
.menu-link ul li a {
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.8);
  transition: 0.5s;
  text-decoration: underline;
  font-weight: 600;
  padding-right: 0;
  margin-left: 0;
  padding-left: 0;
}
.menu-link ul {
  margin-left: 16rem;
}
}

@media (max-width: 1088px) {
.main-nav {
height: 7rem;
grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
}
.menu-link {
  display: none;
  }
.social-media ul {
height: 7rem;
}
.mobile-menu-link {
  grid-column: 2/4;
  position: relative;
  z-index: 99;
}

.mobile-menu-link {
    border: 1px groove #ffc107;
    background-color: rgb(0, 0, 0);
    height: 20rem;
    display: grid;
    grid-column: 2/5;
    height: 250px;
    transition: all 2s linear;
    transform-origin: top;
    box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px;
    justify-content: start;
    width: 97%;
    border-radius: 0px 0px 15px 15px;
}
button.myButton-dc {
width: fit-content;
}
.mobile-menu-link ul {
    height: 25rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
    margin: 0 auto;
  }
.mobile-menu-link ul li:first-child {
    transition-delay: 0.2s;
  }
.social-media {
    display: flex;
    justify-items: end;
    align-items: center;
    transition: all 2s linear;
    z-index: 100;
  }
.social-media .social-media-desktop {
    height: 0;
    display: none;
}
.social-media {
    height: 7rem;
    display: flex;
    justify-self: end;
    align-items: center;
}
.social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
    margin-right: 5rem;
}

.svg-nav{
  width: 35px;
  height: 35px;
}
.account-row {
  display: flex;
  margin-left: 2rem;
}

}

@media (max-width: 820px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
  }
  .mobile-menu-link {
    height: 25rem;
    width: 98%;
  }
  .menu-link ul li a {
   font-size: 2rem;
}
.account-wrap {
  margin-top: 1rem;
}

.social-media ul {
    height: 6rem;
  }
  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
    margin-right: 3rem;
  }
  .social-media .hambuger-menu-active{
    margin-bottom: 3px;
    display: block;
    font-size: 2.5rem;
    margin-right: 3rem;
  }
}

@media (max-width: 780px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
  }
  .mobile-menu-link {
    height: 25rem;
    width: 97%;
  }
  .menu-link ul li a {
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.8);
    transition: 0.5s;
    text-decoration: none;
}
.social-media .hamburger-menu {
  margin-top: 1px;
  display: block;
  font-size: 2.5rem;
  margin-right: 3rem;
}
.social-media .hambuger-menu-active {
  margin-bottom: 0px;
  display: block;
  font-size: 2.5rem;
  margin-right: 3rem;
}

  .social-media ul {
    height: 6rem;
  }

.logo-img{
    width: 40px; 
    height: 40px; 
}
.nav-a {
  margin-top: 1rem;
}
 
  /* hero section  */

  .hero-section h1 {
    font-size: 3.8rem;
  }
}
@media (max-width: 680px) {
.main-nav {
  height: 6rem;
  grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
}
.mobile-menu-link {
  width: 98%;
}
.social-media .hambuger-menu-active{
  margin-bottom: 3px;
  display: block;
  font-size: 2.5rem;
  margin-right: 10px;
}
.social-media ul {
    height: 6rem;
}
.social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
}
.social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
    margin-right: 22px;
}

}
@media (max-width: 540px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
  }
  .mobile-menu-link {
    height: 26rem;
    width: 98%;
}
  .menu-link ul li a {
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.8);
    transition: 0.5s;
    text-decoration: none;
}
  .logo,
.social-media ul {
  height: 6rem;
  }
.logo h2 {
  font-size: 2rem;
  }
.social-media .hamburger-menu {
  display: block;
  font-size: 2.5rem;
  }
.logo-img{
  width: 40px; 
  height: 40px; 
  margin-bottom: 2px ;
}

.account-row {
  margin-top: 2rem;
}
  /* hero section  */

  .hero-section h1 {
    font-size: 3.8rem;
  }
}
@media(max-width: 489px){
  .mobile-menu-link {
    width: 95%;
}
.menu-link ul li a {
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.8);
  transition: 0.5s;
  text-decoration: underline;
  font-weight: 600;
  padding-right: 3rem;
  margin-left: 1rem;
  padding-left: 0rem; 
}
.nav-a {
  margin-top: 0.5rem;
}
}